import React from "react"
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import SubscribeButton from './subscribe'
import Social from './social'
import AppearText from '../animetext';
import Typography from '@material-ui/core/Typography';

const view6 = ({ backColor, height }) => (

    <Container id='findme'>
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{ width: `100%`, margin: `auto` }} // height: `800px`,
        >
            <Grid container
                item xs={12}
                direction="row"
                justify="space-between"
                alignItems="stretch"
                style={{ marginTop: `25px 0px` }}
            >
                <Grid item xs={12} md={5} style={{ alignContent: `flex-end` }}>
                    <div
                        style={{
                            textAlign: 'start'
                        }}
                    >
                        <AppearText text='Locate Me' no='5' />
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    <div
                        style={{
                            height: `100%`,
                            textAlign: 'end',
                            display: `flex`,
                            justifyItems: 'center',
                            placeItems: 'center',
                            flexDirection: 'row-reverse'
                        }}
                    >
                        <Typography variant="body1" component="h5">
                            "I am easy to find, when I want to be found"
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <div style={{ padding: `1rem` }}>
                <Grid item xs={10}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="stretch"
                    style={{ flex: `auto`, fontFamily: `Roboto`, lineHeight: `1.3`, margin: 'auto' }}
                >
                    <Grid item xs={12}>
                        <Typography variant="body1" component="h5" style={{ fontSize: `1.2rem` }}>
                            <b>I am currently accepting new freelance projects and internship offers.</b><br /><br />
                            The best way to reach out to me would be via email at <a className='sociallinks' href="mailto:contact@kiteretsu.tech">contact@kiteretsu.tech</a><br />
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} style={{ fontSize: `1.2rem` }}>
                        <span>
                            To stay up to date with my new projects and blogs, or just my random rant/informal updates
                            consider signing up for</span><span style={{ color: "blue" }}> "not a newsletter"
                        </span>.
                    </Grid>
                    <Grid item xs={12} style={{ margin: 'auto', height: '100px' }}>
                        <SubscribeButton />
                    </Grid> */}
                    <Grid item xs={12} style={{ marginTop: '2rem' }}>
                        <Social />
                    </Grid>
                </Grid>
            </div>
        </Grid>
    </Container>
)

// view1.propTypes = {
//     backColor: PropTypes.string,
// }

// view1.defaultProps = {
//     backColor: `red`,
//     height: `100`
// }

export default view6
