import React from "react"
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardCarousel from "../cardcarousel";
import Typography from '@material-ui/core/Typography';
import AppearText from '../animetext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby";

const view2 = ({ backColor, height }) => (

    <Container style={{paddingTop: `100px`}} id='projects'>
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{width: `100%`, margin: `auto` }} // height: `800px`,
        >

            <Grid container
                item xs={12}
                direction="row"
                justify="space-between"
                alignItems="stretch"
                style={{
                    margin: `15px 0px`
                }}
            >
                <Grid item xs={12} md={5}>
                    <div
                        style={{
                            textAlign: 'start'
                        }}
                    >
                        <Typography variant="body1" component="h5">
                            This is everything from my professional work to team-work to hackathons.
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} style={{ alignContent: `flex-end` }}>
                    <div
                        style={{
                            textAlign: 'end'
                        }}
                    >
                        <AppearText text='Projects' no='1'/>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            textAlign: 'end'
                        }}
                    >
                        <Link className='textLinks' to='/projects'>
                            See all <FontAwesomeIcon icon={faLongArrowAltRight} size='1'/>
                        </Link>
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                style={{ flex: `auto` }}
            >
                <Grid item xs={12}>
                    <CardCarousel category="projects"></CardCarousel>
                </Grid>
            </Grid>
        </Grid>
    </Container>
)

// view1.propTypes = {
//     backColor: PropTypes.string,
// }

// view1.defaultProps = {
//     backColor: `red`,
//     height: `100`
// }

export default view2
