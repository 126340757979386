import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CurvedArrow from "react-curved-arrow";
import { Portal } from 'react-portal';
import StyledBackgroundSection from './images/cardimage'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from "gatsby";
import "./css/cards.css";


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        marginTop: 5,
        marginBottom: 5
    },
    media: {
        height: 140,
    },
});


function buttons(type, props) {
    if (props.details.frontmatter.type === 1) {
        return <CardActions style={{ justifyContent: 'center' }}>
            <Link to={props.details.frontmatter.url}>
                <Button size="small" color="primary">
                    Read More
                </Button>
            </Link>
        </CardActions>
    } else if (props.details.frontmatter.type === 2) {
        return (<CardActions style={{ justifyContent: 'space-around' }}>
            <a href={props.details.frontmatter.code} target='_blank' rel="noreferrer">
                <Button size="small" color="primary">
                    Code
                </Button>
            </a>
            <a href={props.details.frontmatter.url} target='_blank' rel="noreferrer">
                <Button size="small" color="primary">
                    Demo
                </Button>
            </a>
        </CardActions>)
    } else if (props.details.frontmatter.type === 3) {
        return <CardActions style={{ justifyContent: 'center' }}>
            <Button size="small" color="primary" href={props.details.frontmatter.url} target='_blank' rel="noreferrer">
                Read More
                </Button>
        </CardActions>
    } else if (props.details.frontmatter.type === 4) {
        return <CardActions style={{ justifyContent: 'space-around' }}>
            <a href={props.details.frontmatter.code} target='_blank' rel="noreferrer">
                <Button size="small" color="primary">
                    Code
                </Button>
            </a>
        </CardActions>
    }
}


export default function MyCard(props) {
    const classes = useStyles();
    const [arrow, setArrow] = useState(false);
    const [ccord, setCcord] = useState({});

    function getCoords(elem) { // crossbrowser version
        var box = elem.getBoundingClientRect();
        var body = document.body;
        var docEl = document.documentElement;

        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;

        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left), height: box.height, width: box.width };
    }

    function cardhovered(e) {
        var div = e.target.closest(".slick-slide")
        var coords = getCoords(div);
        var x = document.createElement("div");
        var t = document.createTextNode(props.details.frontmatter.comment);
        // var l = props.details.frontmatter.comment.length
        if (window.innerWidth / 2 > (coords.left + coords.width / 2)) {
            // Left part
            x.style.left = (coords.left + coords.width / 2) + "px";
            x.style.textAlign = `left`;
            x.style.paddingLeft = `5px`;
            var flag = -1
        } else {
            flag = 1
            x.style.left = (coords.left) + "px";
            x.style.textAlign = `right`;
            x.style.paddingRight = `10px`;
        }
        x.style.top = (coords.top + coords.height + 70) + "px";
        x.style.width = `250px`
        x.style.position = "absolute";
        x.setAttribute("class", "cardtool")
        x.appendChild(t);
        document.body.appendChild(x);
        div.classList.add('arrowactive');
        var c = {
            'fromoffsetX': flag * (x.getBoundingClientRect().width / 2 + 5),
            'fromoffsetY': 0,
            'tooffsetX': flag * coords.width / 4,
            'tooffsetY': -coords.height / 2 - 20,
            'middleX': flag * 80,
            'middleY': -80,
        }
        setCcord(c);
        setArrow(true);
    }

    function tiphide(e) {
        var ele = document.getElementsByClassName("cardtool")[0];
        ele.parentNode.removeChild(ele);
        document.getElementsByClassName("arrowactive")[0].classList.remove("arrowactive");
        setArrow(false);
        // console.log('Hovered out');
    }

    if (props.details.frontmatter.excerpt != null && props.details.frontmatter.excerpt.length >= 90) {
        props.details.frontmatter.excerpt = props.details.frontmatter.excerpt.substring(0, 90) + '...'
    }

    useEffect(function () {
        // window.addEventListener(eventName, eventListener);

        return function cleanup() {
            var ele = document.getElementsByClassName("cardtool")[0];
            if (ele) {
                ele.parentNode.removeChild(ele);
            }
        }
    }, []);

    return (
        <div onMouseLeave={tiphide} onMouseEnter={cardhovered}>
            <div className="ccard">
                <Card className={classes.root} variant='outlined'>
                    {props.details.frontmatter.type === 1 ?
                        <CardActionArea>
                            <Link to={props.details.frontmatter.url}>
                                <StyledBackgroundSection src={props.details.frontmatter.image} />
                                <CardContent style={{ height: '175px' }} >
                                    <Typography gutterBottom variant="h6" component="h4">
                                        {props.details.frontmatter.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <span dangerouslySetInnerHTML={{ __html: props.details.frontmatter.excerpt }} />
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                        :
                        <CardActionArea href={props.details.frontmatter.code} target="newtab">
                            <StyledBackgroundSection src={props.details.frontmatter.image} />
                            <CardContent style={{ height: '175px' }} >
                                <Typography gutterBottom variant="h6" component="h4">
                                    {props.details.frontmatter.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <span dangerouslySetInnerHTML={{ __html: props.details.frontmatter.excerpt }} />
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    }
                    {buttons(props.details.frontmatter.type, props)}
                </Card>
            </div>
            {
                arrow ?
                    <Portal>
                        <CurvedArrow fromSelector=".cardtool"
                            debugLine={false} toSelector=".arrowactive"
                            color="#FB6D39" zIndex="500"
                            fromOffsetX={ccord.fromoffsetX} fromOffsetY={ccord.fromoffsetY}
                            toOffsetX={ccord.tooffsetX} toOffsetY={ccord.tooffsetY}
                            middleX={ccord.middleX} middleY={ccord.middleY} dynamicUpdate={true} />
                    </Portal>
                    : ''
            }
        </div>
    );
}
