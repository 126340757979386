import React from "react"
// import { Link } from "gatsby"
import Container from '@material-ui/core/Container';
import View1 from "../components/heroview/view1"
import View2 from "../components/projects/view2"
// import View3 from "../components/experiments/view3"
import View4 from "../components/blogs/view4"
import View5 from "../components/stuff/stuff"
import View6 from "../components/contact/contact"
import Layout from "../components/layout"
import "../components/css/home.css"
// import { useTheme } from '@material-ui/core/styles';
import SEO from "../components/seo"


export default function IndexPage() {
  // const theme = useTheme();
  // console.log(theme);
  return (
    <Layout page="index">
      <SEO title="Karan Sheth" />
      <div className="full_cont">
        <Container maxWidth="md">
          <View1></View1>
          <View2></View2>
          <div className='viewDivider'></div>
          {/* <View3></View3> */}
          <View4></View4>
          <div className='viewDivider'></div>
          <View5></View5>
          <div className='viewDivider'></div>
          <View6></View6>
        </Container>
      </div>
    </Layout>
  )
}
