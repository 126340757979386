import React, { Component } from "react";
// import PropTypes from "prop-types";
import anime from "animejs";
import "./css/animetext.css";
import VizSensor from 'react-visibility-sensor';


export default class AppearText extends Component {
    animated = false;
    animateText(isVisible) {
        if (isVisible && !this.animated) {
            anime.timeline({ loop: false })
                .add({
                    targets: `.m${this.props.no} .line`,
                    scaleY: [0, 1],
                    opacity: [0.5, 1],
                    easing: "easeOutExpo",
                    duration: 700
                })
                .add({
                    targets: `.m${this.props.no} .line`,
                    translateX: [0, document.querySelector(`.m${this.props.no} .letters`).getBoundingClientRect().width + 10],
                    easing: "easeOutExpo",
                    duration: 700,
                })
                .add({
                    targets: `.m${this.props.no} .letter`,
                    opacity: [0, 1],
                    easing: "easeOutExpo",
                    duration: 600,
                    delay: (el, i) => 34 * (i + 1)
                }, '-=775')
                .add({
                    targets: `.m${this.props.no} .line`,
                    scaleY: [1, 0],
                    opacity: [1, 0],
                    easing: "easeOutExpo",
                    duration: 700
                });
            this.animated = true;
        }

    }

    render() {
        return (
            <VizSensor
                onChange={(isVisible) => this.animateText(isVisible)}
            >
                <h1 className={"m" + this.props.no + " ml11"}>
                    <span className="text-wrapper">
                        <span className="line line1"></span>
                        <span className="letters">
                            {this.props.text.split("").map((v, index) => (
                                <span className="letter" key={index}>{v}</span>
                            ))}
                        </span>
                    </span>
                </h1>
            </VizSensor>
        )
    }
}
