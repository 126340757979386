import React, { Component } from "react"
import * as d3 from "d3";
import "../css/neuralnet.css"

class Neuralnet extends Component {
    data = [
        {
            "row": 1,
            "col": 1,
            "value": "I",
            "color": "EFEFEF",
            "connection": [
                "1",
                "2",
                "4"
            ]
        },
        {
            "row": 1,
            "col": 2,
            "value": "You",
            "color": "EFEFEF",
            "connection": [
                "1",
                "3"
            ]
        },
        {
            "row": 1,
            "col": 3,
            "value": "We",
            "color": "EFEFEF",
            "connection": [
                "1",
                "3",
            ]
        },
        {
            "row": 2,
            "col": 1,
            "value": "like",
            "color": "C9CCEB",
            "connection": [
                "1",
                "5",
                "6"
            ]
        },
        {
            "row": 2,
            "col": 2,
            "value": "am",
            "color": "C9CCEB",
            "connection": [
                "2",
                "3",
                "4",
                "7",
                "8",
                "9"
            ]
        },
        {
            "row": 2,
            "col": 3,
            "value": "are",
            "color": "C9CCEB",
            "connection": [
                "4",
                "5"
            ]
        },
        {
            "row": 2,
            "col": 4,
            "value": "know",
            "color": "C9CCEB",
            "connection": [
                "1",
            ]
        },
        {
            "row": 3,
            "col": 1,
            "value": "cloud",
            "color": "FED878",
            "connection": []
        },
        {
            "row": 3,
            "col": 2,
            "value": "a student",
            "color": "FED878",
            "connection": []
        },
        {
            "row": 3,
            "col": 3,
            "value": "a Robotics Enthusiast",
            "color": "FED878",
            "connection": []
        },
        {
            "row": 3,
            "col": 4,
            "value": "groot",
            "color": "FED878",
            "connection": []
        },
        {
            "row": 3,
            "col": 5,
            "value": "you",
            "color": "FED878",
            "connection": []
        },
        {
            "row": 3,
            "col": 6,
            "value": "docker",
            "color": "FED878",
            "connection": []
        },
        {
            "row": 3,
            "col": 7,
            "value": "awesome",
            "color": "FED878",
            "connection": []
        },
        {
            "row": 3,
            "col": 8,
            "value": "a Maker",
            "color": "FED878",
            "connection": []
        },
        {
            "row": 3,
            "col": 9,
            "value": "learning RL",
            "color": "FED878",
            "connection": []
        }
    ]

    act_path = []
    intersecting_paths = []
    sim_flag = true
    getCoords(elem) { // crossbrowser version
        var box = elem.getBoundingClientRect();
        var body = document.body;
        var docEl = document.documentElement;

        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;

        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left), height: box.height, width: box.width };
    }
    
    
    un = this.updateNeurons.bind(this)
    componentDidMount() {
        // $("#neuralnet").empty();
        document.getElementById("neuralnet").innerHTML = "";
        var width = document.getElementById('neuralnet').offsetWidth,
            height = document.getElementById('neuralnet').offsetHeight;

        var svg = d3.select("#neuralnet").append("svg")
            .attr("width", width)
            .attr("height", height);

        var coords = this.getCoords(d3.select("svg")._groups[0][0]);
        this.drawNeurons(this.data, coords);
        this.drawAxons(this.data, coords, svg);
        
        this.simulate(this.data);
        
        window.addEventListener("resize", this.un);
        this.handlehover();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.un);
        this.sim_flag = false
    }

    updateNeurons() {
        if (this.sim_flag) {
            document.getElementById("neuralnet").innerHTML = "";
            var width = document.getElementById('neuralnet').offsetWidth,
                height = document.getElementById('neuralnet').offsetHeight;

            var svg = d3.select("#neuralnet").append("svg")
                .attr("width", width)
                .attr("height", height);

            var coords = this.getCoords(d3.select("svg")._groups[0][0]);
            this.drawNeurons(this.data, coords);
            this.drawAxons(this.data, coords, svg);
        }
    }

    drawNeurons(data, coords) {
        var col1 = data.filter(function (e) {
            return e.row === 1;
        });
        var col2 = data.filter(function (e) {
            return e.row === 2;
        });
        var col3 = data.filter(function (e) {
            return e.row === 3;
        });


        var neurons = d3.select("#neuralnet").selectAll("div");
        // var fcord = this.getCoords(d3.select(".full_cont")._groups[0][0]);
        if (coords.width >= 500) {
            var a = 100
            var b = 185
        } else {
            var a = 100
            var b = 185 
        }
        
        neurons.data(col1)
            .enter()
            .append("div")
            .attr("class", "nodes col1")
            .attr("id", function (d, i) {
                return 'n' + d.row + d.col;
            })
            .style("left", 0 + coords.left + "px")
            .style("top", function (d, i) {
                return coords.top + coords.height / 2 - (col1.length / 2) * 25 - ((col1.length - 1) / 2) * 50 + i * 75 + "px";
            })
            .append("span")
            .html(function (d) {
                return d.value;
            });
        
        neurons.data(col2)
            .enter()
            .append("div")
            .attr("class", "nodes col2")
            .attr("id", function (d, i) {
                return 'n' + d.row + d.col;
            })
            // .style("width", "100px")
            // .style("height", "25px")
            .style("left", coords.left + coords.width / 2 - a + "px")
            .style("top", function (d, i) {
                return coords.top + coords.height / 2 - (col2.length / 2) * 25 - ((col2.length - 1) / 2) * 45 + i * 70 + "px";
            })
            .append("span")
            .html(function (d) {
                return d.value;
            });

        neurons.data(col3)
            .enter()
            .append("div")
            .attr("class", "nodes col3")
            .attr("id", function (d, i) {
                return 'n' + d.row + d.col;
            })
            .style("left", coords.left + coords.width - b + "px")
            .style("top", function (d, i) {
                return coords.top + coords.height / 2 - (col3.length / 2) * 25 - ((col3.length - 1) / 2) * 30 + i * 55 + "px";
                // return coords.y + coords.height / 2 - col2.length * 50 + i * 45 + "px";
            })
            .append("span")
            .html(function (d) {
                return d.value;
            });

    };

    drawAxons(data, coords, svg) {
        var axons_pos = []
        for (let i = 0; i < data.length; i++) {
            const neuron = data[i];
            let pos = {}
            let me = '#n' + neuron.row + neuron.col
            me = this.getCoords(d3.select(me)._groups[0][0])
            let x1 = me.left + me.width - coords.left
            let y1 = me.top + me.height / 2 - coords.top
            neuron.connection.forEach(ele => {
                pos = {}
                pos.x1 = x1
                pos.y1 = y1
                var you = '#n' + (neuron.row + 1) + ele
                you = this.getCoords(d3.select(you)._groups[0][0])
                pos.x2 = you.left - coords.left
                pos.y2 = you.top + you.height / 2 - coords.top
                pos.id = 'l' + neuron.row + neuron.col + (neuron.row + 1) + ele
                axons_pos.push(pos)
            });
        }
        // console.log(axons_pos);
        svg.selectAll("line")
            .data(axons_pos)
            .enter()
            .append("line")
            .attr("x1", function (d) {
                return d.x1;
            })
            .attr("y1", function (d) {
                return d.y1;
            })
            .attr("x2", function (d) {
                return d.x2;
            })
            .attr("y2", function (d) {
                return d.y2;
            })
            .attr("stroke-width", 1)
            .attr("stroke", "black")
            .attr("class", "axons")
            .attr("id", function (d) {
                return d.id;
            });
    }

    get_paths = function (data, neuron) {
        var combs = []
        for (let i = 0; i < neuron.connection.length; i++) {
            var path = []
            let ele = neuron.connection[i];

            var next_neuron = {}
            var axon = {}
            next_neuron.id = 'n' + (neuron.row + 1) + ele;
            axon.id = 'l' + neuron.row + neuron.col + (neuron.row + 1) + ele;
            var next = data.filter(function (e) {
                return e.row === (neuron.row + 1) && e.col === parseInt(ele);
            });
            next = next[0];
            next_neuron.color = next.color;
            if (neuron.row === 1) {
                axon.color = 'red'
            } else {
                axon.color = 'lightgreen'
            }
            path = [axon, next_neuron]
            if (next.connection.length === 0) {
                combs.push(path)
            } else {
                const paths = this.get_paths(data, next);
                for (let j = 0; j < paths.length; j++) {
                    const e = paths[j];
                    e.unshift(...path);
                }
                combs.push(...paths);
            }

        }
        return combs
    }

    reset_neuralnetwork = function () {
        var items = document.getElementsByClassName("nodes");
        for (var i = 0; i < items.length; i++) {
            items[i].style.opacity = "10%";
        }
    }

    simulate = async function (data) {
        
        var col = data.filter(function (e) {
            return e.row === 1;
        });
        // console.log(col);
        var combs = []
        for (let n of col) {
            var neuron = {}
            neuron.id = 'n' + n.row + n.col;
            neuron.color = n.color;
            var paths = this.get_paths(data, n);
            for (let i = 0; i < paths.length; i++) {
                const ele = paths[i];
                ele.unshift(neuron);
            }
            combs.push(...paths);
        }
        this.combs = combs;
        var active_path = combs[Math.floor(Math.random() * combs.length)];
        var old_path = []
        var visited_paths = []
        while (this.sim_flag) {
            try {
            
                while (this.pause) {
                    await new Promise(r => setTimeout(r, 200));
                }
                this.act_path = active_path;
                if (!visited_paths.includes(active_path))
                    visited_paths.push(active_path)
                if (visited_paths.length == combs.length) {
                    console.log("Ressetuing")
                    this.reset_neuralnetwork();
                    await new Promise(r => setTimeout(r, 1000));
                }

                for (let i = 0; i < active_path.length; i++) {
                    const ele = active_path[i];
                    // if (ele == old_path[i]) {
                    //     continue
                    // }
                    if (ele.id[0] === 'n') {
                        d3.select('#' + ele.id)
                            .style("opacity", "100%")
                            .style("background-color", "#" + ele.color);
                        document.getElementById(ele.id).classList.add("raised");
                    } else {
                        d3.select('#' + ele.id)
                            .style("opacity", "100%")
                            .attr("stroke", ele.color)
                            .attr("stroke-width", 1.5);
                    }
                }

                await new Promise(r => setTimeout(r, 2000));

                var new_path = combs[Math.floor(Math.random() * combs.length)];
                while (new_path == active_path) {
                    new_path = combs[Math.floor(Math.random() * combs.length)];
                }
                old_path = active_path;

                while (this.pause) {
                    await new Promise(r => setTimeout(r, 200));
                }

                for (let i = 0; i < active_path.length; i++) {
                    const ele = active_path[i];
                    // if (ele == old_path[i]) {
                    //     continue
                    // }
                    if (ele.id[0] === 'n') {
                        d3.select('#' + ele.id)
                            .style("opacity", "10%")
                            .style("background-color", "#E3E4E4");
                        document.getElementById(ele.id).classList.remove("raised");
                    } else {
                        d3.select('#' + ele.id)
                            .style("opacity", "15%")
                            .attr("stroke", "black");
                    }
                }
                await new Promise(r => setTimeout(r, 200));


                active_path = new_path;
            }
            catch (err) {
                console.log('Badger Badger');
            }
        }
    }

    nodehover (node) {
        this.pause = true; // Pause Animation
        // Turn off all paths, a bit ehh
        for (let i = 0; i < this.combs.length; i++) {
            const p = this.combs[i];
            for (let i = 0; i < p.length; i++) {
                const ele = p[i];
                if (ele.id[0] === 'n') {
                    d3.select('#' + ele.id)
                        .style("opacity", "10%")
                        .style("background-color", "#E3E4E4");
                    document.getElementById(ele.id).classList.remove("raised");
                } else {
                    d3.select('#' + ele.id)
                        .style("opacity", "15%")
                        .attr("stroke", "black");
                }
            }
        }
        // Turn on all nodes with hovered node in path
        var cur_node = node.target.closest(".nodes");
        this.intersecting_paths = this.combs.filter(k => k.some(e => e.id === cur_node.id));
        for (let i = 0; i < this.intersecting_paths.length; i++) {
            const p = this.intersecting_paths[i];
            for (let i = 0; i < p.length; i++) {
                const ele = p[i];
                if (ele.id[0] === 'n') {
                    d3.select('#' + ele.id)
                        .style("opacity", "100%")
                        .style("background-color", "#" + ele.color);
                    document.getElementById(ele.id).classList.add("raised");
                } else {
                    d3.select('#' + ele.id)
                        .style("opacity", "100%")
                        .attr("stroke", ele.color)
                        .attr("stroke-width", 1.5);
                }
            }

        }
    }
    resume() {
        // Turn off all intersecting paths
        for (let i = 0; i < this.intersecting_paths.length; i++) {
            const p = this.intersecting_paths[i];
            for (let i = 0; i < p.length; i++) {
                const ele = p[i];
                if (ele.id[0] === 'n') {
                    d3.select('#' + ele.id)
                        .style("opacity", "10%")
                        .style("background-color", "#E3E4E4");
                    document.getElementById(ele.id).classList.remove("raised");
                } else {
                    d3.select('#' + ele.id)
                        .style("opacity", "15%")
                        .attr("stroke", "black");
                }
            }

        }
        // turn on the last active path
        for (let i = 0; i < this.act_path.length; i++) {
            const ele = this.act_path[i];
            if (ele.id[0] === 'n') {
                d3.select('#' + ele.id)
                    .style("opacity", "100%")
                    .style("background-color", "#" + ele.color);
                document.getElementById(ele.id).classList.add("raised");
            } else {
                d3.select('#' + ele.id)
                    .style("opacity", "100%")
                    .attr("stroke", ele.color)
                    .attr("stroke-width", 1.5);
            }
        }
        // resume animation
        this.pause = false;
    }

    handlehover = function() {
        var el = document.getElementsByClassName("nodes");
        console.log(el)
        for (let i = 0; i < el.length; i++) {
            console.log(i);
            el[i].addEventListener("mouseover", this.nodehover.bind(this));
            el[i].addEventListener("mouseout", this.resume.bind(this));
        }
    }

    render() {
        return (
            <div
                style={{ width: '100%', height: '550px', margin: 'auto' }}
                id="neuralnet"
            >
            </div>

        )
    }

}

export default Neuralnet;