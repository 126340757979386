import React from "react"
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import Image from "../images/image"
import Neuralnet from "./neuralnet";
import Typography from '@material-ui/core/Typography';

const view1 = ({ backColor, height }) => (

    <Container id='hero'>
        <Grid
            container
            direction="row"
            justify="flex-start"
        >
            <Grid item xs={12} className='herotop'>
                <div
                    style={{
                        marginTop: `4rem`,
                        marginBottom: `4rem`,
                    }}
                >
                    <Typography variant="h4" component="h4">
                        Heyyo kind Internet person,
                    </Typography>
                    <br/>
                    <Typography variant="body1" component="h5">
                        I go by <b>Karan Sheth</b> in the physical plane,<br />
                        but known as <b>kiteretsu</b> a.k.a <b>korusuke</b> in this realm.
                    </Typography>
                </div>
            </Grid>
            <Grid
                item xs={12} className='herobottom'
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
            >
                <Grid item xs={12} md={5} style={{alignSelf: `flex-end`}} className="from">
                    {/* <div style={{ maxWidth: `225px` }}>
                        <Image/>
                    </div> */}
                </Grid>
                <Grid item xs={12} md={7} style={{ alignSelf: `flex-start` }} className="to">
                    <Neuralnet></Neuralnet>
                </Grid>
            </Grid>
        </Grid>
    </Container>
)

// view1.propTypes = {
//     backColor: PropTypes.string,
// }

// view1.defaultProps = {
//     backColor: `red`,
//     height: `100`
// }

export default view1
