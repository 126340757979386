import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faKeybase, faMedium, faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons'
import Grid from '@material-ui/core/Grid';
import '../css/social.css'
import Typography from '@material-ui/core/Typography';
import { Icon, InlineIcon } from '@iconify/react';
import codechefIcon from '@iconify/icons-simple-icons/codechef';

var buttons = [
    {
        'title': 'Github',
        'icon': faGithub,
        'link': 'https://github.com/korusuke',
        'color': 'black'
    },
    {
        'title': 'Team',
        'icon': faGithub,
        'link': 'https://github.com/team-probably',
        'color': 'black'
    },
    {
        'title': 'Linkedin',
        'icon': faLinkedin,
        'link': 'https://linkedin.com/in/kiteretsu',
        'color': 'black'
    },
    {
        'title': 'Twitter',
        'icon': faTwitter,
        'link': 'https://twitter.com/kiteretsu_',
        'color': 'black'
    },
    {
        'title': 'Keybase',
        'icon': faKeybase,
        'link': 'https://keybase.io/kiteretsu',
        'color': 'black'
    },
    // {
    //     'title': 'Medium',
    //     'icon': faMedium,
    //     'link': 'https://medium.com/@teamprobably',
    //     'color': 'black'
    // },
    {
        'title': 'Codechef',
        'icon': codechefIcon,
        'link': 'https://www.codechef.com/users/korusuke',
        'color': 'black'
    },
    {
        'title': 'Telegram',
        'icon': faTelegram,
        'link': 'https://t.me/korusuke',
        'color': 'black'
    }
]


function spawnWorlds() {
    var worlds = []
    for (let i = 0; i < buttons.length; i++) {
        const ele = buttons[i];
        var world = <Grid item key={i + 1} className='sbw'>
            {ele.title != 'Codechef' ?
                <a className='sociallinks' href={ele.link} rel="noreferrer" target='_blank'>
                    <FontAwesomeIcon icon={ele.icon} /> &nbsp;{ele.title}
                </a>
                :
                <a className='sociallinks' href={ele.link} rel="noreferrer" target='_blank'>
                    <Icon icon={codechefIcon} /> &nbsp;{ele.title}
                </a>
            }
        </Grid>
        worlds.push(world);
    }
    return worlds
}


export default function Social(props) {
    // const [on, setOn] = useState(false)

    return (
        <Grid container className="socialWrapper"
            spacing={1}>
            <Grid item xs={12}>
                <Typography variant="body1" component="p">
                    You can find, connect or follow me in the following worlds:
                </Typography>
            </Grid>

            <Grid container item xs={12} spacing={2} justify='space-around' style={{ marginTop: '1rem' }}>
                {spawnWorlds()}
            </Grid>
        </Grid>
    );
}