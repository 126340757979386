import React from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/mycard.css";
import Slider from "react-slick";
import MyCard from "./mycard";
import { StaticQuery, graphql } from "gatsby";

var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 680,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


function createCards(data, category) {
    let cards = []
    const { edges: posts } = data.allMdx;
    // console.log(posts);
    // Outer loop to create parent posts.frontmatter
    for (let i = 0; i < posts.length; i++) {
        if (posts[i].node.frontmatter.public && posts[i].node.frontmatter.mainpage) {
            if (category === 'projects') {
                if (posts[i].node.frontmatter.category === 'projects' || posts[i].node.frontmatter.category === 'experiments') {
                    cards.push(<MyCard key={i + 1} details={posts[i].node}></MyCard>)
                }
            } else {
                if (posts[i].node.frontmatter.category === category) {
                    cards.push(<MyCard key={i + 1} details={posts[i].node}></MyCard>)
                }
            }
             
        }
    }
    return cards
};

// (pruneLength: 90)

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allMdx(sort: {fields: frontmatter___index}) {
                    edges {
                        node {
                            id
                            frontmatter {
                            category
                            title
                            comment
                            image
                            code
                            url
                            date
                            excerpt
                            public
                            mainpage
                            type
                            }
                        }
                    }
                }
            }
        `}

        render={data => (
            <Slider {...settings}>
                {createCards(data,props.category)}
            </Slider>
        )}
    />
);
